import { FC, MouseEventHandler, ReactNode } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from 'classnames'

interface Props {
  tooltip: string
  tooltipFor?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
  type?: 'button' | 'submit' | 'reset'
  actionClasses?: string
  disabled?: boolean
  children: ReactNode
}

const Button: FC<Props> = ({
  tooltip,
  tooltipFor,
  onClick,
  className = '',
  type = 'button',
  actionClasses = 'action',
  disabled = false,
  children,
}) => (
  <button
    data-tip={tooltip}
    data-for={tooltipFor}
    onClick={onClick}
    type={type}
    disabled={disabled}
    className={classNames('p-2 rounded', actionClasses, className)}
  >
    {children}
  </button>
)

export default Button
