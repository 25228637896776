import { FC, forwardRef, InputHTMLAttributes } from 'react'
import classNames from 'classnames'
import { useField } from 'formik'
import { Label } from 'components/common/label'
import { FiZap } from 'react-icons/fi'
import classes from './input.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    required?: boolean
    className?: string
    errorSize?: number
    errorIconSize?: number
    icon?: React.ReactNode
    additional?: React.ReactNode
}

export const BaseInput: FC<Omit<Props, 'name'>> = (
  { className, icon, required, ...props },
  ref,
) => (
  <div
    className={classNames(
      'rounded grow flex flex-row items-center bg-gray-300 action',
      className,
    )}
  >
    {icon && <div className="ml-1">{icon}</div>}
    <input
      ref={ref}
      size={1}
      className={`grow rounded bg-gray-300 px-2 py-1.5${className}`}
      required={required}
      {...props}
    />
  </div>
)

export const BaseInputRef: any = forwardRef(
  // @ts-ignore
  ({ icon, required, ...props }, ref) => (
    <div className={classes.box}>
      {icon && <div className="ml-1">{icon}</div>}
      <input
        // @ts-ignore
        ref={ref}
        size={1}
        className={classNames(classes.input, '')}
        required={required}
        {...props}
      />
    </div>
  ),
)

export const Input: FC<Props> = ({
  name,
  icon,
  required = false,
  additional,
  errorSize = 12,
  errorIconSize = 18,
  ...rest
}) => {
  const [field, meta] = useField(name)

  return (
    <div className={classes.outerbox}>
      <div
        className={classes.box}
        style={
          additional
            ? {
              paddingRight: '0.5rem',
            }
            : {}
        }
      >
        {icon && <div className="ml-1">{icon}</div>}
        <input
          className={classes.input}
          required={required}
          {...field}
          {...rest}
        />
        {additional && <div className="ml-1">{additional}</div>}
      </div>
      {meta.touched && meta.error ? (
        <Label>
          <p className="font-poppins" style={{ fontSize: errorSize }}>
            {meta.error}{' '}
          </p>
          <span
            style={{
              fontSize: errorIconSize,
            }}
          >
            <FiZap />
          </span>
        </Label>
      ) : null}
    </div>
  )
}
