import React, { FC, ReactNode } from 'react'
import classes from './Card.module.scss'

const Card = ({ classname, children }: { classname?: string, children: ReactNode | ReactNode[] }) =>
  <div className={`${classname} ${classes.card}`}>{children}</div>

export interface CardTitleProps {
  children: string
}

const Title: FC<CardTitleProps> = ({ children }) => <div className={classes.title}>{children}</div>

export interface CardContentProps {
  children: ReactNode | ReactNode[]
}

const Content: FC<CardContentProps> = ({ children }) => <div className={classes.content}>{children}</div>

export interface CardActionsProps {
  children: ReactNode | ReactNode[]
}

const Actions = ({ children }: CardActionsProps) => <div className={classes.actions}>{children}</div>

export interface CardActionsMainProps {
  children: ReactNode
}

const Main: FC<CardActionsMainProps> = ({ children }) => <div className={classes.main}>{children}</div>

export interface CardActionsSubProps {
  children: ReactNode | ReactNode[]
}

const Sub: FC<CardActionsSubProps> = ({ children }) => <div className={classes.sub}>{children}</div>

Actions.Main = Main
Actions.Sub = Sub

Card.Title = Title
Card.Content = Content
Card.Actions = Actions

export { Card }
