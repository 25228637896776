import { GoogleLogin } from '@react-oauth/google'
import { BASE_URL } from '../../env'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useAuthContext } from '../../hooks/AuthContext'

export default function GoogleAuth() {
  const [screenWidth, setScreenWidth] = useState(0)
  const { actions: contextActions } = useAuthContext()

  const router = useRouter()
  const handleResize = () => {
    setScreenWidth(window.innerWidth)
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="absolute -bottom-14">
      <GoogleLogin
        onSuccess={async (credentialResponse) => {
          await fetch(`${BASE_URL}/api/v1/auth/social/google-oauth2/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(
              { access_token: credentialResponse.credential },
            ),
          }).then(async (res) => {
            if (res.ok) {
              const body = await res.json()
              const key = body?.token || body?.key
              if (key) {
                contextActions.setKey(key)
                router.push('/user')
              }
            }
          })
        }}
        onError={() => {
          console.log('Login Failed')
        }}
        width={screenWidth > 767 ? 400 : screenWidth > 424 ? 353 : screenWidth > 374 ? 303 : 248}
        // width={screenWidth > 767 ? 481 : screenWidth > 424 ? 393 : screenWidth > 374 ? 343 : 288}
      />
    </div>
  )
}
