import React, { ReactNode } from 'react'
import classes from './Label.module.scss'

export interface LabelProps {
  isLabel?: boolean
  variant?: 'primary' | 'seccondary' | 'tool' | 'tool2'
  children: ReactNode | ReactNode[]
}

const Label = ({
  isLabel = false,
  variant = 'primary',
  children,
}: LabelProps) => {
  if (isLabel) {
    return (
      <label className={`${classes.label} ${classes[variant]}`}>
        {children}
      </label>
    )
  }
  return (
    <div className={`${classes.label} ${classes[variant]} max-sm:w-full`}>{children}</div>
  )
}

const LabelBox = ({ children }: { children: ReactNode | ReactNode[] }) => (
  <div className={classes.labelbox}>{children}</div>
)

Label.Box = LabelBox

export { Label }
