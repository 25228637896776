import { Label } from '../common/label'
import { Input } from '../common/field/input'
import { IconButton } from '../common/action/IconButton'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import React, { useState } from 'react'
import { FormikValues } from 'formik'
import { BASE_URL } from '../../env'

interface LoginFormProps {
    setLoading: (loading: boolean) => void;
    knownUser: boolean;
    setKnownUser: (knownUser: boolean) => void;
    setPassAuth: (passAuth: boolean) => void;
    setUnsettingToken: (unsettingToken: boolean) => void;
    setGlobalError: (error: string) => void;
    passAuth: boolean;
    tempGlobalError: any | null;
    props: any;
}

export default function LoginForm({
  setLoading,
  knownUser,
  setKnownUser,
  setPassAuth,
  setUnsettingToken,
  setGlobalError,
  passAuth,
  tempGlobalError,
  props,
}: LoginFormProps) {
  const [isShowPassword, setShowPassword] = useState<boolean>(false)
  async function resetPassword(values: FormikValues) {
    setLoading(true)
    await fetch(`${BASE_URL}/api/v1/auth/unset/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: values.email }),
    }).then(async (res) => {
      console.log(res)
      if (res.ok) {
        const body = await res.json()
        setKnownUser(true)
        const authTypePass = body?.auth_type === 'password'
        setPassAuth(authTypePass)
        setUnsettingToken(true)
      } else {
        const body = await res.json()
        setGlobalError(body?.non_field_errors.join())
        console.log('non_field_errors: ', body?.non_field_errors.join())
      }
    })
    setLoading(false)
  }
  return (
    <>
      {knownUser && (passAuth
        ? (
          <>
            <Label.Box>
              <Label>password</Label>
              <Input
                id="password-input"
                name="password"
                placeholder="Enter a password"
                type={isShowPassword ? 'text' : 'password'}
                additional={(
                  <IconButton
                    tooltip={
                      isShowPassword ? 'hide password' : 'show password'
                    }
                    type="button"
                    onClick={() => {
                      setShowPassword(!isShowPassword)
                    }}
                  >
                    {isShowPassword ? <FiEye /> : <FiEyeOff />}
                  </IconButton>
                )}
              />
            </Label.Box>
            <div className="cursor-pointer"
              onClick={() => resetPassword(props.values)}>
              <Label>
                <p className="underline">Forgot password? Click here!</p>
              </Label>
            </div>
          </>
        )
        : (
          <Label.Box>
            <Label>verification code</Label>
            <Input
              name="token"
              placeholder="Enter a token"
              type="text"
            />
            <Label>
              We just sent you a temporary login code.<br />
              Please check your inbox (and spam folder)
            </Label>
          </Label.Box>
        )
      )}
      {tempGlobalError && <Label>{tempGlobalError}</Label>}
    </>

  )
}
