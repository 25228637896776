import classNames from 'classnames'
import React, { FC, MouseEventHandler, ReactNode } from 'react'
import classes from './IconButton.module.scss'

export interface ShowButtonProps {
  tooltip: string
  tooltipFor?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
  type?: 'button' | 'submit' | 'reset'
  actionClasses?: string
  disabled?: boolean
  children: ReactNode | ReactNode[]
}

const IconButton: FC<ShowButtonProps> = ({
  children,
  tooltip,
  tooltipFor,
  onClick,
  type,
  disabled,
  actionClasses,
  className,
}) => (
  <button
    data-tip={tooltip}
    data-for={tooltipFor}
    onClick={onClick}
    type={type}
    disabled={disabled}
    className={classNames(
      'p-2',
      classes.iconButton,
      actionClasses,
      className,
    )}
  >
    {children}
  </button>
)

export { IconButton }
